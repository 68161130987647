.link {
	text-decoration: none;
	color: lightGray;
}

.active {
	font-weight: bold;
}

.main {
	font-weight: bold;
}
