.otherMonth {
  width: calc(1 / 7 * 100%);
  background: #ccc;
  border-right: solid 1px #ccc;
}

.normal {
  width: calc(1 / 7 * 100%);
  background: rgb(255, 255, 255);
  border: solid 0.01em rgba(204, 204, 204, 0.25);
}

.today {
  width: calc(1 / 7 * 100%);
  background: rgba(97, 212, 247, 0.25);
  border: solid 0.01em rgba(204, 204, 204, 0.25);
}
